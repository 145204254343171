// i18next-extract-mark-ns-start paypal-for-wix
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import wix_paypal from 'images/wix_paypal.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="paypal-for-wix"
        title={t('Wix PayPal integration')}
        description={t(
          'Add the Wix PayPal integration to your website to let customers pay with the most used digital wallet in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Wix PayPal integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                PayPal is the most popular digital wallet globally, making it essential to accept it
                in your Wix store. To do this, you’ll need a payment gateway that supports a Wix
                PayPal integration.{' '}
              </Trans>
              <Trans parent="p">
                We’re here to help. Our advanced e-commerce payment gateway lets you accept and
                manage all the most popular payment methods in a single platform. This way, you can
                focus on growing your e-commerce business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={wix_paypal} alt="Wix paypal" title="Wix paypal" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Manage all your Wix payments from one easy-to-use dashboard</Trans>
              </SectionHeader>
              <Trans parent="p">
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink> is widely
                used, but there’s an abundance of{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                you can add to your Wix store to boost customer satisfaction. Meet the demands of
                shoppers by offering many payment options including PayPal and other{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> such as{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>.
                As well as{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>{' '}
                and <BlogLink slug="local-payment-methods">local payment methods</BlogLink> such as{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                Our goal is to help you accept as many online payment methods as possible. This way,
                you’ll reach more people, improve the checkout experience, and sell more. That’s why
                we combine all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into a single platform.
              </Trans>
              <Trans parent="p">
                We’re adding more payment methods regularly,{' '}
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  sign up here to get notified
                </AnchorLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Optimize your sales by approving more online payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Reduce{' '}
                <BlogLink slug="online-payment-failure-message">
                  online payment failure messages
                </BlogLink>{' '}
                and lost sales by setting up payment processing rules. This way, instead of
                depending on <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink>{' '}
                alone to process transactions, you can use our{' '}
                <Link to="/features/payments-orchestration/">payments orchestration</Link> feature
                to choose a default payment processor. And if your provider is experiencing
                downtime, we’ll send the payment to a different, better-performing one.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Get the lowest Wix payment gateway pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                While you’re growing a business, every cent counts. That’s why we’re the only
                payment gateway to offer scaled pricing. This way, as you sell more, your
                transaction fees decrease in real-time.
              </Trans>
              <Trans parent="p">
                Choose a <Link to="/wix-payment-gateway/">Wix payment gateway</Link> that adapts to
                your business needs — <Link to="/pricing/">view pricing here</Link>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add the Wix PayPal integration to your website</Trans>
            </SectionHeader>
            <Trans parent="p">
              Follow these steps to quickly and easily add a PayPal button to your Wix store:
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/articles/360017812937-How-do-I-add-PayPal-to-my-account-">
                  Configure PayPal payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/wix/">
                  Wix integration guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
              <Trans parent="li">
                Review our{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/articles/4406122571025-How-to-enable-MONEI-s-payment-gateway-in-Wix-">
                  Wix support article here
                </AnchorLink>
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "paypal-for-wix"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
